import * as React from "react"
import "./layout.css"

const Layout = ({ children }) => {
  return (
    <>
      <div
      >
        <main>{children}</main>
       <footer
          style={{
            marginTop: `15px`,
            fontSize: `1em`,
            textAlign: `center`,
            fontFamily: `Georgia,Times New Roman,imes,serif`,
            fontWeight: "bold",
          }}
        >
         © 2017 - {new Date().getFullYear()} &middot;Досуг-сз.рф | <a href="/dosug.atom">RSS</a>
        </footer>
      </div>
    </>  
  )
}
export default Layout


